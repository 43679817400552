import Routes, { generateUrl } from '@/routes';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import {
  AUTH0_AUDIENCE,
  AUTH0_CACHE_LOCATION,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ISSUER,
} from '@/environment';

export function getAuth0Config(): Auth0ClientOptions {
  return {
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    redirect_uri: `${window.location.origin}${generateUrl(Routes.loginCallback)}`,
    audience: AUTH0_AUDIENCE,
    issuer: AUTH0_ISSUER,
    cacheLocation: AUTH0_CACHE_LOCATION,
    useRefreshTokens: true
  };
}

export default function initializeAuth0(): Auth0Client {
  // @ts-ignore
  if (typeof window === 'undefined') return;
  return new Auth0Client(getAuth0Config());
}
