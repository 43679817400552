import { Auth0Client } from '@auth0/auth0-spa-js';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState, 
} from 'react';

import { trackRouteChanges } from '@/analytics';
import constants from '@/constants';
import { initializeApplication } from '@/context/application/initializer';
import {
  AccountDto,
  Maybe, 
} from '@/graphql';
import { useStorage } from '@/hooks/useStorage';

import initializeApollo from './initializers/apollo';
import initializeAuth0 from './initializers/auth0';
import initializeSentry from './initializers/sentry';
import {
  ApplicationContextInterface,
  ComplianceProviderTypeEnum,
} from './types';

const auth0Client: Auth0Client = initializeAuth0();

const defaultContext: ApplicationContextInterface = {
  account: null,
  apolloClient: null,
  auth0Client,
  authenticating: false,
  checkFlag: () => false,
  complianceProviderType: null,
  error: null,
  featureFlags: null,
  initializing: true,
  logout: () => null,
  reInitApplication: () => false,
  user: null,
};

const ApplicationContext = createContext(defaultContext);

export const useApplication = (): ApplicationContextInterface =>
  useContext(ApplicationContext);

export const ApplicationProvider: FC = ({ children }) => {
  const [context, setContext] = useState(defaultContext);
  const [accountId] = useStorage(constants.ACCOUNT_ID);

  function getComplianceProviderType(account: Maybe<AccountDto> | null) {
    const complianceProviderName =
      account?.vendor?.jurisdiction?.complianceProvider?.name;

    const complianceProviderType = {
      isBioTrack:
        complianceProviderName === ComplianceProviderTypeEnum.BIOTRACK,
      isGrowFlow:
        complianceProviderName === ComplianceProviderTypeEnum.GROWFLOW,
      isLeafData:
        complianceProviderName === ComplianceProviderTypeEnum.LEAFDATA,
      isMetrc: complianceProviderName === ComplianceProviderTypeEnum.METRC,
    };

    return complianceProviderType;
  }

  const initApplication = async function () {
    const apolloClient =
      context.apolloClient || (await initializeApollo(auth0Client));

    const applicationContext = await initializeApplication({
      ...context,
      apolloClient,
    });

    const complianceProviderType = getComplianceProviderType(
      applicationContext.account,
    );

    applicationContext.initializing = false;

    setContext({ ...applicationContext, complianceProviderType });
  };

  const reInitApplication = async function () {
    context.initializing = true;

    setContext({ ...context });

    await context.apolloClient?.clearStore();
    await initApplication();
  };

  useEffect(() => {
    initializeSentry();
    trackRouteChanges();
  }, []);

  // Re-init the application context when the jurisdiction of account changes... and run it the first time of course.
  useEffect(() => {
    initApplication();
  }, [accountId]);

  return (
    <ApplicationContext.Provider
      value={{
        ...context,
        reInitApplication,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
