import { Auth0Client } from '@auth0/auth0-spa-js';
import {
  GRAPHQL_ENDPOINT,
  isProductionEnvironment,
} from '@/environment';
import constants from '@/constants';
import storage from '@/storage';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMessageFromError, notifyError } from '@/errors';
import { GraphQLError } from 'graphql';
import strings from '@/strings';
import * as Sentry from '@sentry/react';

export default async function initializeApollo(auth0Client: Auth0Client): Promise<ApolloClient<NormalizedCacheObject>> {
  const cache = new InMemoryCache({ addTypename: true });

  return new ApolloClient({
    connectToDevTools: !isProductionEnvironment,
    cache,
    link: ApolloLink.from([
      onError((apolloError) => {
        const { graphQLErrors = [], networkError, operation } = apolloError;
        let errorNotifications = [];

        // TODO: DO SOMETHING BETTER....ASK API TO BE CONSISTENT with ERRORS
        if (!graphQLErrors?.length) {
          if (networkError && 'result' in networkError) {
            errorNotifications.push(getMessageFromError(networkError.result[0]));
          } else if (networkError && 'statusCode' in networkError) { // https://github.com/apollographql/apollo-link/issues/300#issuecomment-518445337
            console.error(`Network error, code: ${networkError.statusCode}, message: ${networkError.statusCode}, errorBody: ${networkError}`);

            switch (networkError.statusCode) {
              case 401: // unauthorized
                // Easiest way is to refresh the page. If we did something more sophisticated, we would set a return URL then redirect to Auth0.
                console.error(strings.errorMessages.unauthorized);
                errorNotifications.push(strings.errorMessages.unauthorized);
                window.location.reload();
                break;
              case 403: // forbidden, User does not have access to the resource, Show error notification
                errorNotifications.push(strings.errorMessages.permissions);
                console.error(`Network error, code: ${networkError.statusCode}, message: ${networkError.statusCode}, errorBody: ${networkError}`);
                break;
              default:
                errorNotifications.push(strings.errorMessages.SERVER_ERROR);
                errorNotifications.push(strings.errorMessages.devsNotifiedTryAgain);
                break;
            }
          }
        }

        graphQLErrors.forEach((error: GraphQLError) => {
          Sentry.withScope((scope) => {
            scope.setTag('kind', operation.operationName);
            scope.setExtra('query', operation.query);
            scope.setExtra('variables', operation.variables);
            if (error.path) {
              scope.addBreadcrumb({
                category: 'query-path',
                message: error.path.join(' > '),
                level: Sentry.Severity.Debug
              });
            }
            Sentry.captureException(error);
          });

          errorNotifications.push(getMessageFromError(error));
          errorNotifications.push(strings.errorMessages.devsNotifiedTryAgain);

          console.error(`[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(error.locations)}, Path: ${error.path}`);
        });

        notifyError(errorNotifications.join('\n'));
      }),
      setContext(async (_, { headers }) => {
        const token = await auth0Client.getTokenSilently();
        const accountId = storage.getItem(constants.ACCOUNT_ID);
        // TODO: DELETE THIS FALLBACK to CA
        const jurisdiction = storage.getItem(constants.JURISDICTION_ID) || 'CA';

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            ...accountId ? { accountId } : {},
            ...jurisdiction ? { jurisdiction: jurisdiction.toLowerCase() } : {},
            'content-type': 'application/json',
          },
        };
      }),
      new HttpLink({
        credentials: 'same-origin',
        uri: GRAPHQL_ENDPOINT,
      }),
    ]),
    ssrMode: false,
  });
}
