import {
  isDeployedEnvironment,
  SENTRY_DSN,
  VERSION,
} from '@/environment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default function initializeSentry() {
  if (isDeployedEnvironment) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: VERSION,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1, // We recommend adjusting this value in production, or using tracesSampler for finer control
    });
  }
}
