import { notification } from 'antd';
import strings from '@/strings';
import { ClientParseError } from '@apollo/client';
import { debounce } from 'lodash';

interface GraphqlErrorInterface {
  message: string;
  locations: { line: number; column: number }[];
  path: string[];
}

export const getMessageFromError = (errorObject: GraphqlErrorInterface | Error | ClientParseError ) => {
  // @ts-ignore
  return strings.errorMessages[errorObject?.path?.[0]] ||
    // @ts-ignore
    strings.errorMessages[errorObject?.message] ||
    // @ts-ignore
    strings.errorMessages[errorObject?.Key] ||
    errorObject.message ||
    strings.errorMessages.DEFAULT_GENERIC;
}

export const notifyError = debounce((description: string): void => notification.error({
  description,
  duration: 8,
  message: strings.errorMessages.somethingWentWrong,
  className: 'error-notification',
}));
