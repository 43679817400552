import { AccountDto } from '@/graphql';
import { Auth0Client, User } from '@auth0/auth0-spa-js';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export interface FeatureFlags {
  [key: string]: boolean;
}

export type GrowFlowApolloClient = ApolloClient<NormalizedCacheObject> | null;

export enum ComplianceProviderTypeEnum {
  METRC = 'METRC',
  LEAFDATA = 'LeafData',
  BIOTRACK = 'BioTrack',
  GROWFLOW = 'GrowFlow',
}

type ComplianceProviderType = {
  isMetrc: boolean;
  isLeafData: boolean;
  isBioTrack: boolean;
  isGrowFlow: boolean;
};
export interface ApplicationContextInterface {
  account: AccountDto | null;
  complianceProviderType: ComplianceProviderType | null;
  apolloClient: GrowFlowApolloClient;
  auth0Client: Auth0Client;
  authenticating: boolean;
  checkFlag: (flag?: string) => boolean;
  error: boolean | null;
  featureFlags: FeatureFlags | null;
  initializing: boolean;
  logout: () => void;
  reInitApplication: () => void;
  user: User | undefined | null;
}
